import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { distanceBetweenTwoPoints } from '../../../utils/utilsDistance';
import { ParkingType } from '../../../utils/utilTypes';
import { AllCardParkingLoadingSkeleton } from './Skeleton';
import { CardParkingListItemProps } from './props';
import { useTranslation } from 'react-i18next';
export const CardParkingList = ({
  center,
  parkings,
  isLoading,
  latestEvent,
  onClick,
}: CardParkingListItemProps) => {
  const { t } = useTranslation();
  if (isLoading) {
    return <AllCardParkingLoadingSkeleton />;
  }

  const sorterParkings = parkings
    .map((x) => ({
      ...x,
      distance: distanceBetweenTwoPoints(center, { lat: x.lat, lng: x.lng }),
    }))
    .sort((a, b) => a.distance - b.distance);

  return (
    <Swiper
      className="h-full"
      spaceBetween={0}
      slidesPerView={1.8}
      scrollbar={{ draggable: true }}
    >
      {sorterParkings
        .map((parking: ParkingType) => {
          const isParkingInSession = latestEvent?.parking?.id === parking.id;
          const isDisabled = parking.isAtFullCapacity && !isParkingInSession;

          return {
            ...parking,
            isParkingInSession,
            isDisabled,
          };
        })
        .sort(
          (a: ParkingType, b: ParkingType) =>
            Number(b.isParkingInSession) - Number(a.isParkingInSession)
        )
        .map((parking: ParkingType) => {
          return (
            <SwiperSlide
              key={parking.id}
              style={{ height: '100%' }}
              className={`flex flex-1 min-w-[184px] max-w-[184px]  h-full justify-center flex-col px-3   ${
                parking.isDisabled && 'cursor-default opacity-50'
              }`}
              onClick={() => !parking.isDisabled && onClick(parking)}
            >
              <div className="rounded-general shadow-xl h-[100px] mb-3 relative">
                {parking?.photo && (
                  <>
                    <Image
                      priority
                      className="rounded-general relative"
                      src={parking?.photo?.image?.publicUrl}
                      layout="fill"
                      objectFit="fill"
                      alt={parking?.photo?.image?.altText}
                    />

                    <div className="rounded-general absolute  top-0 left-0 w-full h-full opacity-40 bg-[#000]"></div>
                  </>
                )}
                <div className="absolute text-xs font-bold text-white bottom-2 right-3">
                  {parking.capacity} {t('spots')}
                </div>
              </div>
              <div className="text-md font-bold text-black overflow-hidden whitespace-nowrap overflow-ellipsis">
                {parking.name}
              </div>
              <div className="mt-1 text-xs max-w-[192px] text-gray-dark overflow-hidden whitespace-nowrap overflow-ellipsis">
                {parking.full_address || t('noAddress')}
              </div>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};
